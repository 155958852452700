import React from 'react'
import { ProductMaterial, ProductData , SelectButtonOption, ParsedSku } from '../../../types'
import SelectButton from '../../generic/SelectButton'
import { buildPesUrl } from '../../../utils/UrlUtils'
import { getMaterialGroups } from '../../../utils/utils'
import { getCartCountry } from '../../../utils/CountrySelection'

interface MaterialSelectProps {
  materials: ProductMaterial[]
  productInformation: ParsedSku
  material: string
  currentMaterial?: ProductMaterial
  productData: ProductData
}

const MaterialSelect: React.FC<MaterialSelectProps> = ({
  materials,
  productInformation,
  material,
  currentMaterial,
  productData,
}) => {
  const selectedKey = '' + productInformation.materialIId

  const templateUrl = buildPesUrl(
    material,
    '%s',
    productInformation.motiveId,
    productInformation.slug,
    productData.ratio,
  )

  const options: SelectButtonOption[] = []

  let groupBy: string | undefined = undefined

  if (getMaterialGroups().indexOf(currentMaterial?.materialgroup) !== -1) {
    groupBy = currentMaterial?.material
  }

  materials.map((material: ProductMaterial) => {
    if (typeof groupBy === 'undefined' || groupBy === material.material) {
      options.push({
        key: '' + material.iid,
        name: material.name,
        inactive: material.out_of_stock,
      })
    }
  })

  const buttonName = 'MaterialSelect'

  return (
    <SelectButton
      name={buttonName}
      options={options}
      replace={true}
      selectedKey={selectedKey}
      templateUrl={templateUrl}
    />
  )
}

export default MaterialSelect
