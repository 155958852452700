import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useMaterialsData } from '../../lib/materials_hook'
import { ProductData, ProductMaterial } from '../../types'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import { localStorage_getItem } from '../../utils/LocalStorageHelper'
import { isConnectedMaterialBundle } from '../../utils/Mapping'
import ArtboxoneCarousel from '../generic/ArtboxoneCarousel'
import ProductPreview from '../product/Preview'
import '../../styles/last_visited.css'

interface LastVisitedProps {
  url: string
}

interface LastProduct extends ProductData {
  material: string
  variant: string
}

const CarouselWrapper = ({ items }: { items: Array<JSX.Element> }) => (
  <ArtboxoneCarousel showThumbs={false}>
    {items.map((item) => item)}
  </ArtboxoneCarousel>
)

const removeDuplicateProducts = (products: LastProduct[]) => {
  const result: Array<LastProduct> = []

  products.forEach((product) => {
    if (!result.find((item) => item.id === product.id)) result.push(product)
  })

  return result
}

const getLastVisitedProducts = (products: LastProduct[]) => {
  const result = removeDuplicateProducts(products)

  const startIndex = products.length > 6 ? products.length - 7 : 0
  const lastIndex = products.length - 1

  return result.slice(startIndex, lastIndex).reverse()
}

export const LOCALSTORAGE_LAST_VISITED =
  process.env.STORAGE_PREFIX + '_lastVisitedProducts'

const LastVisited: React.FC<LastVisitedProps> = ({ url }) => {
  const { t } = useTranslation('translation')
  const materialsData = useMaterialsData()

  const products = localStorage_getItem(LOCALSTORAGE_LAST_VISITED)
  const lastVisitedProducts = products
    ? getLastVisitedProducts(JSON.parse(products))
    : []

  const handlePreviewClick = (label: string) => {
    trackEvent({
      category: 'Latest-Products,',
      action: 'Click',
      label,
    })
  }

  const items = lastVisitedProducts.map((product: LastProduct, i: number) => {
    const { material, variant, ...rest } = product

    return (
      <div className="col-12 col-sm-4" key={'product_preview_' + i}>
        <ProductPreview
          material={material}
          materials={materialsData.filter((materialData: ProductMaterial) => {
            return isConnectedMaterialBundle(materialData, material)
          })}
          productData={rest}
          trackClick={handlePreviewClick}
          variant={variant}
        />
      </div>
    )
  })

  useEffect(() => {
    trackEvent({
      category: 'Latest-Products,',
      action: 'Show',
      label: url,
    })
  }, [])

  return (
    <div className="last_visited mb-4">
      {lastVisitedProducts.length > 0 && (
        <>
          <div className="row">
            <div className="col-md">
              <h2>{t('Zuletzt angesehen')}</h2>
            </div>
          </div>
          <div className="row">
            {isMobile ? <CarouselWrapper items={items} /> : items}
          </div>
        </>
      )}
    </div>
  )
}

export default LastVisited
