import React from 'react'
import { ProductData } from '../../types'
import { LinearProgress } from '@mui/material'
import ProductPreview from './Preview'
import { getCrosslinkProducts } from '../../utils/ProductUtils'

interface CrosslinksSameMotiveProps {
  productData: ProductData
  currentMaterial: string
  setOnClick: any
}

const CrosslinksSameMotive = (props: CrosslinksSameMotiveProps) => {
  const display = getCrosslinkProducts(
    props.productData,
    props.currentMaterial,
    false,
  )
  // display = display.reverse()

  const isLoading = typeof display === 'undefined'

  if (isLoading) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md">
            <LinearProgress />
          </div>
        </div>
      </div>
    )
  } else if (display.length == 0) {
    return <></>
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md">
            <h2>Auch verfügbar als</h2>
          </div>
        </div>
        <div className="row">
          {display.map((p: any) => (
            <div className="col-6 col-sm-4" key={p.signature}>
              <ProductPreview
                hideMaterialSize={true}
                hidePrice={true}
                hideTitle={true}
                material={p.material}
                materials={p.materialData}
                productData={p.productData}
                setOnClick={props.setOnClick}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default CrosslinksSameMotive
