import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { CartItem, ProductData, ProductMaterial } from '../../../types'
import SearchContext from '../../../context/SearchContext'
import {
  getCartItemsCount,
  getCartItemCountPerProduct,
  addCartItem,
  removeCartItem,
} from '../../../lib/cartitems_hook'
import { buildSku } from '../../../utils/ProductUtils'
import { trackEvent } from '../../../utils/ArtboxoneTracking'
import { navigate } from 'gatsby'
import { buildUpsellingUrl } from '../../../utils/UrlUtils'
import TechnicalErrorDialog from '../../generic/TechnicalErrorDialog'

export interface BuyButtonProps {
  text: string
  cartItem: CartItem
  productData: ProductData
  material?: ProductMaterial
  outOfStock?: boolean
  hideIcon?: boolean
}

export default function BuyButton({
  cartItem,
  productData,
  material,
  outOfStock,
  text,
  hideIcon = false,
}: BuyButtonProps) {
  const { setCartCount } = useContext(SearchContext)
  const { t } = useTranslation('translation')
  const [errorOpen, setErrorOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleAddToCart = () => {
    setLoading(true)

    trackEvent({
      category: 'pes',
      action: 'addToCart',
      label: buildSku(cartItem.material, cartItem.motiveId),
    })

    cartItem.count = getCartItemCountPerProduct(cartItem) + 1
    removeCartItem(cartItem)

    if (addCartItem(cartItem)) {
      setCartCount(getCartItemsCount())

      navigate(
        buildUpsellingUrl(
          cartItem.material,
          productData.id,
          productData.urlslug,
          cartItem.ratio,
          material?.url_slug,
        ),
      )
    } else {
      setErrorOpen(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        className="cta_pes_button"
        color="primary"
        data-test="cta_pes_add2cart_button_upselling_standard"
        disabled={outOfStock || loading}
        onClick={handleAddToCart}
        variant="contained"
      >
        {!hideIcon && <AddShoppingCartIcon />}
        <div
          dangerouslySetInnerHTML={{
            __html: t(outOfStock ? 'Zur Zeit leider ausverkauft' : text),
          }}
        />
      </Button>
      <TechnicalErrorDialog open={errorOpen} setOpen={setErrorOpen} />
    </>
  )
}
