import React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { navigate } from 'gatsby'
import { SelectButtonOption } from '../../types'
import { mapInternalKey2Slug } from '../../utils/UrlUtils'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import { withUrlSlugs } from './withUrlSlugs'
import { useTranslation } from 'react-i18next'

export interface SelectButtonProps {
  options: SelectButtonOption[]
  name: string
  selectedKey: string
  templateUrl?: string
  handleSelect?: any
  replace: boolean
  stopPropagation?: boolean
}

const SelectButton = (props: SelectButtonProps) => {
  const options = props.options

  const { t } = useTranslation('translation')
  const selectedKey = props.selectedKey
  const templateUrl = props.templateUrl
  const handleSelect = props.handleSelect

  let selectedIndex = 0
  const keys: string[] = []
  const keyMapping: Record<string, string> = {}

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  options.map((option: SelectButtonOption) => {
    keyMapping[option.key] = mapInternalKey2Slug(option.key)
  })

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    key: string,
  ) => {
    trackEvent({
      category: props.name,
      action: 'Set',
      label: keys.indexOf(key) + '-' + key,
    })

    if (typeof templateUrl === 'string') {
      const url = templateUrl.replace('%s', keyMapping[key])
      navigate(url, { replace: props.replace })
    } else if (typeof handleSelect !== 'undefined') {
      handleSelect(key)
    }

    setOpen(false)

    if (props.stopPropagation === true) {
      event.stopPropagation()
    }
  }

  const handleToggle = (event: any) => {
    if (open) {
      trackEvent({
        category: props.name,
        action: 'Toggle',
        label: 'close',
      })
    } else {
      trackEvent({
        category: props.name,
        action: 'Toggle',
        label: 'open',
      })
    }

    setOpen((prevOpen) => !prevOpen)

    if (props.stopPropagation === true) {
      event.stopPropagation()
    }
  }

  options.map((option, i) => {
    if (option.key == selectedKey) {
      selectedIndex = i
    }

    keys.push(option.key)
  })

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  if (keys.indexOf(selectedKey) === -1) {
    return <></>
  } else {
    return (
      <>
        <ButtonGroup
          aria-label="split button"
          ref={anchorRef}
          variant="contained"
        >
          <Button color="secondary" onClick={handleToggle}>
            {t(options[selectedIndex].name.replace(':', '--')).replace(
              '--',
              ':',
            )}
          </Button>
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            aria-label="select merge strategy"
            color="primary"
            data-test={props.name}
            onClick={handleToggle}
            size="small"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popper
          anchorEl={anchorRef.current}
          disablePortal
          open={open}
          role={undefined}
          style={{ zIndex: 999 }}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option) => (
                      <MenuItem
                        data-test={props.name + '_' + option.key}
                        disabled={
                          option.inactive === true ? option.inactive : false
                        }
                        key={option.key}
                        onClick={(event) =>
                          handleMenuItemClick(event, option.key)
                        }
                        selected={option.key === selectedKey}
                      >
                        {t(option.name.replace(':', '--')).replace('--', ':')}
                        {option.inactive === true &&
                          ' (' + t('Ausverkauft') + ')'}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    )
  }
}

export default withUrlSlugs(SelectButton)
