import React from 'react'
import {
  Ratio,
  SelectButtonOption,
  ProductMaterial,
  ProductData,
  ProductMaterialImages,
} from '../../../types'
import { useRatiosData } from '../../../lib/products/ratios_hook'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import SelectButton from '../../generic/SelectButton'
import { useMaterialsData } from '../../../lib/materials_hook'
import { buildPesUrl } from '../../../utils/UrlUtils'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'

function getModalStyle() {
  return {
    margin: 'auto',
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

interface RatioSelectProps {
  materialgroup: string
  currentMaterial?: ProductMaterial
  productData: ProductData
}

const RatioSelect: React.FC<RatioSelectProps> = ({
  materialgroup,
  currentMaterial,
  productData,
}) => {
  const productType = currentMaterial?.material
  const allRatios = productData.allRatios

  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(false)
  const [selectedRatio, setSelectedRatio] = React.useState(
    '' + productData.ratio,
  )

  const allRatiosIds: number[] = []

  if (allRatios !== undefined) {
    allRatios.map((r: Ratio) => {
      allRatiosIds.push(r.id)
    })
  }

  const { t } = useTranslation('translation')

  const ratios = useRatiosData().filter((r: Ratio) => {
    return allRatiosIds.indexOf(r.id) !== -1
  })

  const materialPerRatio: Record<string, ProductMaterial> = {}

  useMaterialsData()
    .filter((m: ProductMaterial) => {
      if (m.materialgroup != materialgroup) {
        return false
      }

      if (allRatiosIds.indexOf(m.ratio) !== -1) {
        return true
      }

      if (m.ratio == 2) {
        if (allRatiosIds.indexOf(6) !== -1) {
          return true
        }
      } else if (m.ratio == 6) {
        if (allRatiosIds.indexOf(2) !== -1) {
          return true
        }
      }

      return false
    })
    .map((m: ProductMaterial) => {
      const checkRatio = '' + m.ratio

      if (m.materialgroup === 'advent') {
        if (
          m.material == productType ||
          typeof materialPerRatio[m.ratio] === 'undefined'
        ) {
          materialPerRatio[checkRatio] = m
        }
      } else {
        if (
          (typeof m.iid !== 'undefined' && m.iid == currentMaterial?.iid) ||
          (m.material == currentMaterial?.material &&
            materialPerRatio[m.ratio]?.material != m.material) ||
          typeof materialPerRatio[m.ratio] === 'undefined'
        ) {
          if (checkRatio == '2') {
            materialPerRatio['6'] = m
          } else if (checkRatio == '6') {
            materialPerRatio['2'] = m
          }

          materialPerRatio[checkRatio] = m
        }
      }
    })

  const handleOpen = (event: any) => {
    setOpen(true)
  }

  const handleClose = (event: any) => {
    setOpen(false)
  }

  const handleApply = (event: any) => {
    setOpen(false)
  }

  const handleSelect = (key: string) => {
    setSelectedRatio('' + key)
    const ratio = parseInt(key)

    if (typeof materialPerRatio[ratio] !== 'undefined') {
      const url = buildPesUrl(
        materialgroup,
        materialPerRatio[ratio].url_slug,
        productData.id,
        productData.urlslug,
        ratio,
      )

      setOpen(false)
      navigate(url)
    }
  }

  const options: SelectButtonOption[] = []
  ratios.map((r: Ratio) => {
    options.push({ key: '' + r.id, name: r.name })
  })

  if (options.length > 1 && [5, 7].indexOf(productData.ratio) === -1) {
    return (
      <div>
        <Button
          className="cta_pes_button"
          color="primary"
          onClick={handleOpen}
          variant="outlined"
        >
          <AspectRatioIcon /> {t('Seitenverhältnis ändern')}
        </Button>
        <Modal
          aria-describedby="filter-modal-description"
          aria-labelledby="filter-modal-title"
          onClose={handleClose}
          open={open}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className={classes.paper} style={modalStyle}>
            <h2 id="filter-modal-title">{t('Seitenverhältnis ändern')}</h2>
            <p id="simple-modal-description">
              {t(
                'Wir können dir dieses Motiv in unterschiedlichen Seitenverhältnissen produzieren.',
              )}
            </p>

            <div className="row">
              <div className="col d-flex justify-content-center">
                <SelectButton
                  handleSelect={handleSelect}
                  name="RatioSelect"
                  options={options}
                  replace={true}
                  selectedKey={selectedRatio}
                />
              </div>
            </div>

            <div className="row">
              <div className="col d-flex justify-content-center">
                <Button
                  color="primary"
                  onClick={handleApply}
                  style={{ marginTop: `24px` }}
                  variant="contained"
                >
                  <CloseIcon /> {t('Schließen')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  } else {
    return <></>
  }
}

export default RatioSelect
