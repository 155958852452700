import React from 'react'
import { ProductData } from '../../types'
import BundleOverview from '../products/BundleOverview'

interface ArtworksInsideCalendarProps {
  productData: ProductData
  currentMaterial: string
  setOnClick: any
}

const ArtworksInsideCalendar = (props: ArtworksInsideCalendarProps) => {
  return (
    <BundleOverview
      bundleId={props.productData.id}
      key="bundle_calendar"
      page="1"
      removeDuplicates={true}
      setOnClick={props.setOnClick}
    />
  )
}

export default ArtworksInsideCalendar
