import React from 'react'
import { DeliveryTimes } from '../../types'
import { getCartCountry } from '../../utils/CountrySelection'
import { useDeliveryTimes } from '../../lib/deliverytimes_hooks'
import DeliveryTimePresentation from './DeliveryTimePresentation'

interface DeliveryTimeInformationProps {
  materialIId?: number
}

const DeliveryTimeInformation: React.FC<DeliveryTimeInformationProps> = ({
  materialIId,
}) => {
  const items = []
  items.push({ materialIId: materialIId })
  const deliveryTimes: DeliveryTimes | undefined = useDeliveryTimes({
    cartItems: items,
    deliveryCountry: getCartCountry(),
    mode: 'days',
  })

  if (deliveryTimes == undefined) {
    return <></>
  } else {
    return (
      <DeliveryTimePresentation deliveryTimes={deliveryTimes} mode="days" />
    )
  }
}

export default DeliveryTimeInformation
