import React, { useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'
import SEO from '../generic/Seo'
import { useProductData } from '../../lib/product_hooks'
import { renderImageUrl } from '../../lib/imageurl_api'
import {
  ProductMaterial,
  ProductImage,
  Artist,
  AlternateUrl,
 BreadcrumbLayer, ParsedSku , PageInformation } from '../../types'
import FluidProductImage from './FluidProductImage'
import { useMaterialsData } from '../../lib/materials_hook'
import { useArtistsData } from '../../lib/artists_hook'
import { navigate } from 'gatsby'
import ProductConfigurator from './configurator/ProductConfigurator'
import Favorite from './Favorite'
import Breadcrumb from '../artboxone/Breadcrumb'
import { useTranslation } from 'react-i18next'
import {
  mapInternalKey2Slug,
  mapSlug2InternalKey,
  mapInternalProductMaterial2InternalKey,
  getThumbnailReplacementType,
  buildCanonicalUrlsForPes,
  buildAlternateUrlsForPes,
} from '../../utils/UrlUtils'
import { isBrowser } from '../../utils/utils'
import { Helmet } from 'react-helmet'
import Fullscreen from './Fullscreen'
import { MaterialText } from './MaterialText'
import { isConnectedMaterial } from '../../utils/Mapping'
import {
  getCartCountry,
  getCurrencyIso,
  getPriceCountry,
} from '../../utils/CountrySelection'
import { LinearProgress, Button } from '@mui/material'
import CrosslinksSameMotive from './CrosslinksSameMotive'
import CrosslinksSameMaterial from './CrosslinksSameMaterial'
import { buildSku, getCrosslinkProducts } from '../../utils/ProductUtils'
import {
  setEnvironmentVariable,
  pushTmEvent,
} from '../layout/EnvironmentVariables'
import MotiveEdit from '../cms/MotiveEdit'
import ArtistLinks from './ArtistLinks'
import fetchError from '../../lib/global/error_api'
import {env} from '../../../environment'
import JSONLD from '../generic/jsonld/JsonLd'
import { generateJsonLdForProduct } from '../generic/jsonld/Generators'
import CrosslinksButton from './CrosslinksButton'
import ArtworksInsideButton from './ArtworksInsideButton'
import ArtworksInsideCalendar from './ArtworksInsideCalendar'
import ArtboxoneCarousel from '../generic/ArtboxoneCarousel'
import withLocation from '../generic/withLocation'
import { detectWebGLContext } from '../../pixum/detectWebGLContext'
import Preview3dButton from './Preview3dButton'
import { getHash } from '../../utils/Crypto'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import Layout from '../../components/layout/Layout'
import ConfigurationDialog from './configurator/Dialog'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import {
  localStorage_getItem,
  localStorage_setItem,
} from '../../utils/LocalStorageHelper'
import LastVisited, { LOCALSTORAGE_LAST_VISITED } from '../products/LastVisited'
import createMaterialFactory from './configurator/helpers/createMaterial'
import { getSizeFromProductName } from './configurator/helpers/createVariant'

const ArtboxoneModelViewer = React.lazy((): any => {
  return import('@google/model-viewer/lib/model-viewer')
    .then(() => import('../generic/ArtboxoneModelViewer'))
    .catch(error => {
      console.warn(error)
    })
})

export interface ProductConfigurationProps {
  material: string
  productSlug?: string
  sku: string
  ratio: number | undefined
  customText: string | undefined
  url: string
  location?: Location
}

const parseSku = (sku: string) => {
  const result: ParsedSku = {
    motiveId: 0,
    slug: '',
    materialIId: 0,
  }

  if (typeof sku != `undefined`) {
    const matches = sku.match(/([0-9]+)-(.+)/)

    if (matches != null && typeof matches[2] != 'undefined') {
      result.slug = matches[2]
      result.motiveId = parseInt(matches[1])
    }
  }

  return result
}

const ProductConfiguration = (props: ProductConfigurationProps) => {
  const productInformation: ParsedSku = parseSku(props.sku)
  const crossLinkRef = useRef<HTMLDivElement>(null)

  let glb = ''
  let glbHash = ''
  let glbPreview = ''
  let glbTexture = ''
  let glbScale = '1 1 1'
  let materialVariant = ''
  let ar = false
  let glbOrientation = '0deg 0deg 0deg'

  if (typeof props.productSlug !== 'undefined') {
    const materialFromSlug = parseInt('' + mapSlug2InternalKey(props.productSlug))

    if (materialFromSlug !== undefined && !isNaN(materialFromSlug)) {
      productInformation.materialIId = materialFromSlug
    } else if (isBrowser()) {
      fetchError({
        data:
          'Material "' +
          props.productSlug +
          '" not found -> Please check urls (' +
          JSON.stringify(props) +
          ')',
      })

      productInformation.materialIId = 0
      // instead navigate('/404')
    }
  }

  if (isBrowser() && productInformation.motiveId == 0) {
    if (props.sku != '' && props.url != '') {
      // fetchError({
      //   data:
      //     'Missing productinformation from sku -> 404 (' +
      //     JSON.stringify(props) +
      //     ')',
      // })
      // These are some Old urls
      navigate('/404') // OK
    } else {
      fetchError({
        data:
          'Missing productinformation from sku -> ignore? (' +
          JSON.stringify(location.pathname) +
          ')',
      })

      navigate('/404')
    }
  }

  const { t } = useTranslation('translation')

  const materialName = props.material
  const i18nMaterialName = t(materialName)

  const artistsData = useArtistsData()
  const materialsData = useMaterialsData()

  let productData = useProductData({
    material: materialName,
    id: ['' + productInformation.motiveId],
    ratio: props.ratio,
  })

  if (
    productData !== undefined &&
    productData !== null &&
    productData.id != productInformation.motiveId
  ) {
    productData = undefined
  }

  const artistsShow: Artist[] = []

  productData?.artists?.map((artistId: number) => {
    const artists: Artist[] = artistsData.filter((artist: Artist) => {
      return artist.id == artistId
    })

    if (artists.length == 1) {
      artistsShow.push(artists[0])
    }
  })

  if (
    isBrowser() &&
    typeof productData?.urlslug !== 'undefined' &&
    productData?.urlslug != productInformation.slug
  ) {
    fetchError({
      data:
        'Mismatch urlslug from api "' +
        productData?.urlslug +
        '" vs url "' +
        productInformation.slug +
        '" (' +
        JSON.stringify(props) +
        ')',
    })

    // navigate('/404') // TODO navigate to new url or 404?
  }

  const imageUrls: string[] = []

  let filteredMaterialsData = undefined
  let chosenMaterial: ProductMaterial = {
    iid: 0,
    name: '...',
    material: '...',
    images: {
      small: 'https://productimages.artboxone.com/%d-PO-small.jpg',
      large: 'https://productimages.artboxone.com/%d-PO-big.jpg',
      ambiente: '',
    },
    price: {
      de: 0,
    },
    url_slug: '',
    deliveryTime: {
      min: 1,
      max: 14,
      xmas: '',
    },
    customizable: false,
    materialgroup: '',
    out_of_stock: true,
    ratio: 0,
    legacy_id: '0',
  }

  const priceCountry = getPriceCountry(getCartCountry())

  let isCustomizable = chosenMaterial?.customizable && productData?.customizable

  if (productData !== undefined && productData !== null) {
    const lastVisitedProductsInLocalStorage = localStorage_getItem(
      LOCALSTORAGE_LAST_VISITED,
    )
    const lastVisitedProducts = lastVisitedProductsInLocalStorage
      ? JSON.parse(lastVisitedProductsInLocalStorage)
      : []

    const lastVisited = lastVisitedProducts.filter(
      product => product.id !== productData?.id,
    )

    localStorage_setItem(
      LOCALSTORAGE_LAST_VISITED,
      JSON.stringify([
        ...lastVisited,
        {
          material: props.material,
          variant: materialVariant,
          ...productData,
        },
      ]),
    )

    productData !== undefined && productData !== null
    filteredMaterialsData = materialsData
      .filter((materialData: ProductMaterial) => {
        return isConnectedMaterial(materialData, materialName)
      })
      .filter((material: ProductMaterial) => {
        if (productData.materialIIds.indexOf(material.iid) !== -1) {
          return true
        } else {
          return false
        }
      })
      .filter((material: ProductMaterial) => {
        return material.price[priceCountry] > 0.02
      })

    if (filteredMaterialsData.length > 0) {
      if (productInformation.materialIId === 0) {
        if (filteredMaterialsData.length >= 2) {
          chosenMaterial = filteredMaterialsData[1]
        } else {
          chosenMaterial = filteredMaterialsData[0]
        }
      } else {
        const chosenMaterials = filteredMaterialsData.filter(
          (materialData: ProductMaterial) => {
            return materialData.iid === productInformation.materialIId
          },
        )

        if (chosenMaterials.length > 0) {
          chosenMaterial = chosenMaterials[0]
        } else {
          chosenMaterial.iid = 0
          productInformation.materialIId = 0

          // fetchError({
          //   data:
          //     'URL wrong -> missing material, redirect to base material? (' +
          //     JSON.stringify(props) +
          //     ')',
          // })

          // console.log(props.location)
          // console.log('Redirect to', props.location.pathname)
          // navigate(props.location.pathname)
        }
      }

      if (chosenMaterial.iid === 0) {
        chosenMaterial = filteredMaterialsData[0]
      }

      isCustomizable = chosenMaterial?.customizable && productData?.customizable

      materialVariant = chosenMaterial?.material

      const keys = Object.keys(chosenMaterial.images)

      for (const key of keys) {
        if (['small'].indexOf(key) == -1) {
          const thumbnail: ProductImage = {
            template: chosenMaterial.images[key],
            replacement: getThumbnailReplacementType(chosenMaterial.material),
          }

          const imageUrl = renderImageUrl({
            image: thumbnail,
            productData: productData,
            customText: isCustomizable ? props.customText : undefined,
          })

          if (imageUrl && key == 'texture') {
            glbTexture = imageUrl
          } else if (imageUrl) {
            imageUrls.push(imageUrl)
          }
        }
      }
    } else {
      if (materialsData.length > 0) {
        fetchError({
          data: 'No materials (' + JSON.stringify(props) + ')',
        })

        navigate('/404')
      }
    }
  }

  isCustomizable = chosenMaterial?.customizable && productData?.customizable
  let customText = props.customText

  if (!isCustomizable) {
    customText = ''
  }

  const breadcrumbLayers: BreadcrumbLayer[] = []

  let breadcrumbUrl = ''

  const productMaterialKey = mapInternalProductMaterial2InternalKey(materialName)
  breadcrumbUrl += '/' + mapInternalKey2Slug(productMaterialKey)

  breadcrumbLayers.push({
    url: breadcrumbUrl,
    name: t(productMaterialKey),
  })

  breadcrumbUrl += '/' + mapInternalKey2Slug(props.material)
  breadcrumbLayers.push({
    url: breadcrumbUrl,
    name: t(props.material),
  })

  breadcrumbLayers.push({
    url: '',
    name: '' + productData?.name,
  })

  // Canoncial
  const canonicals: JSX.Element[] = []
  buildCanonicalUrlsForPes(props.material, productInformation).map(
    (url: string) => {
      canonicals.push(
        <link href={url} key={'canonical-' + url} rel="canonical" />,
      )
    },
  )

  if (env.getSeo() == '1') {
    buildAlternateUrlsForPes(props.material, productInformation).map(
      (alternateUrl: AlternateUrl) => {
        canonicals.push(
          <link
            href={alternateUrl.url}
            hrefLang={alternateUrl.locale}
            key={'alternate-' + alternateUrl.url}
            rel="alternate"
          />,
        )
      },
    )
  }

  if (
    chosenMaterial.legacy_id !== '0' &&
    productInformation.materialIId === 0
  ) {
    productInformation.materialIId = chosenMaterial.iid
  } else {
    if (filteredMaterialsData !== undefined) {
      filteredMaterialsData.map((materialData: ProductMaterial) => {
        if (productInformation.materialIId === 0) {
          productInformation.materialIId = materialData.iid
        }
      })
    }
  }

  const [fullscreen, setFullscreen] = React.useState(false)
  const [preview3d, setPreview3d] = React.useState(false)
  const [openUpSellingDialog, setOpenUpSellingDialog] = React.useState(false)

  const [crosslinksSameMotiveVisible, setCrosslinksSameMotiveVisible] =
    React.useState(false)

  const isLoading =
    productData === undefined ||
    productData === null ||
    filteredMaterialsData === undefined ||
    imageUrls.length === 0

  let sku = ''

  if (productData) {
    sku = buildSku(materialName, productData.id)
  }

  if (productData === null) {
    fetchError({
      data: 'Could not load motive (' + JSON.stringify(props) + ')',
    })

    const redirectUrl = '/alternatives/' + props.material + '/' + props.sku
    navigate(redirectUrl)
  }

  let robots = 'noindex, follow'

  if (env.getSeo() == '1') {
    robots = 'index, follow'
  }

  const display =
    getCrosslinkProducts(productData || {}, props.material, true) || []

  const { priceForChosenMaterial, sizeForChosenMaterial } = useMemo(() => {
    const areMaterialAndPriceDefined = chosenMaterial && priceCountry

    if (!isLoading && areMaterialAndPriceDefined) {
      return {
        priceForChosenMaterial: chosenMaterial.price[priceCountry],
        sizeForChosenMaterial: getSizeFromProductName(chosenMaterial.name),
      }
    }

    return {}
  }, [chosenMaterial, isLoading, priceCountry])

  const materials = useMemo(() => {
    const materialFactory = createMaterialFactory(display, priceCountry)
    const materials = materialFactory.getMaterialsWithVariants()

    return materials.reduce((result: Record<string, any>[], material) => {
      const variantsPerColor = material.variants.getInStockPerColor(
        priceForChosenMaterial,
        sizeForChosenMaterial,
      )
      const hasVariants = Object.keys(variantsPerColor).length > 0

      if (hasVariants) {
        return [
          ...result,
          {
            name: material.name,
            productData: material.productData,
            signature: material.signature,
            variantsPerColor,
          },
        ]
      } else {
        return result
      }
    }, [])
  }, [display, priceCountry, priceForChosenMaterial, sizeForChosenMaterial])

  if (isLoading) {
    return (
      <>
        <Helmet>
          <meta content={robots} name="robots" />
        </Helmet>
        <>
          <meta content="artboxONE" itemProp="brand" />
          <meta content={sku} itemProp="sku" />
        </>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <LinearProgress />
            </div>
          </div>
        </div>
      </>
    )
  } else {
    // seo
    const seoTitle = t('%s als %t bei artboxONE kaufen')
      .replace('%s', productData.name)
      // .replace('%t', chosenMaterial.name)
      .replace('%t', i18nMaterialName)

    let seoDescription = t('%s als %t bei artboxONE kaufen')
      .replace('%s', productData.name)
      // .replace('%t', chosenMaterial.name)
      .replace('%t', i18nMaterialName)

    if (productData?.description != '') {
      seoDescription = seoDescription + ' - ' + productData?.description
    }

    // Fix for reloading page and scroll to saved position
    if (!crosslinksSameMotiveVisible) {
      window.scrollTo(...(window.lastScrollPosition || [0, 0]))
    }

    const pesData = {
      motiveName: productData.name,
      materialPriceGross: chosenMaterial.price[priceCountry],
      materialName: materialName,
      currency: getCurrencyIso(priceCountry),
    }

    const setStateOn = (val: boolean) => {
      setCrosslinksSameMotiveVisible(true)
      const node = ReactDOM.findDOMNode(crossLinkRef.current) as Element
      node.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }

    const setStateOff = (val: boolean) => {
      setCrosslinksSameMotiveVisible(false)
    }

    let crosslinkButton = <></>

    const buttonText = t('In den Warenkorb')

    let crosstype = 'wallart'

    if (
      [
        'wallcalendar',
        'foldingcalendar',
        'deskcalendar',
        'squarecalendar',
        'familyplaner',
      ].indexOf(materialName) >= 0
    ) {
      crosstype = 'calendar'

      crosslinkButton = (
        <ArtworksInsideButton
          setState={setStateOn}
          state={crosslinksSameMotiveVisible}
        />
      )
    } else {
      crosslinkButton = (
        <CrosslinksButton
          setState={setStateOn}
          state={crosslinksSameMotiveVisible}
        />
      )
    }

    if (
      detectWebGLContext() &&
      imageUrls.length == 1 &&
      chosenMaterial.file_3d != '' &&
      glbTexture != ''
    ) {
      glb = chosenMaterial.file_3d

      // 3D File for portraits?
      if (productData.ratio == 6 && chosenMaterial.file_3d_portrait) {
        glb = chosenMaterial.file_3d_portrait
      }

      if (chosenMaterial?.materialgroup == 'advent') {
        ar = true
      }

      glbPreview = imageUrls[0]

      if (chosenMaterial.scale_3d) {
        glbScale = chosenMaterial.scale_3d
      } else {
        // Do not scale if its a template with size spec
        const doNotScale = glb.indexOf('_') > 0 && glb.indexOf('x') > 0

        if (doNotScale) {
          glbScale = '1 1 1'

          if (productData.ratio == 6) {
            // landscape to portrait
            glbOrientation = '90deg 0deg 0deg'
          }
        } else if (productData.ratio == 6) {
          glbScale = '1.5 1 1'
          glbOrientation = '90deg 0deg 0deg'
        } else if (productData.ratio == 2) {
          glbScale = '1.5 1 1'
        }

        glbHash = getHash({ glb, glbTexture, glbPreview, glbScale })
      }
    }

    if (glb) {
      if (preview3d) {
        trackEvent({
          category: 'PES',
          action: '3D_PREVIEW',
          label: 'ACTIVATED',
        })
      } else {
        trackEvent({
          category: 'PES',
          action: '3D_PREVIEW',
          label: 'AVAILABLE',
        })
      }
    }

    const pageInformation: PageInformation = {
      type: 'pes',
      material: props.material,
      variant: chosenMaterial.material,
    }

    const hasItems = materials.length > 1

    return (
      <>
        <Helmet>
          <meta content={robots} name="robots" />
          {canonicals.map((canonical: JSX.Element) => canonical)}
        </Helmet>
        {setEnvironmentVariable('sku', sku)}
        {setEnvironmentVariable('pageType', 'pes')}
        {setEnvironmentVariable('pes', pesData)}
        {pushTmEvent('artboxone-pes-loaded')}

        <SEO description={seoDescription} title={seoTitle} />
        <Layout pageInformation={pageInformation}>
          <Breadcrumb layers={breadcrumbLayers} />

          <div className="container">
            <div className="row">
              <div className={'col teaser'}>
                <h1>{productData.name}</h1>
                <MotiveEdit motiveId={productData.id} />
                <div className={'d-none d-md-block teasertext '}>
                  {productData.description}
                </div>
              </div>
            </div>

            <div className="row">
              <div className={fullscreen === true ? 'col-md-12' : 'col-md-6'}>
                {preview3d && glb && (
                  <div key={'pes_3d_' + (fullscreen ? 'f' : 'n')}>
                    <ArtboxoneModelViewer
                      ar={ar}
                      fullscreen={fullscreen}
                      glb={glb}
                      key={'3d_view_' + glbHash + '-' + (ar ? 'ar' : 'non-ar')}
                      orientation={glbOrientation}
                      preview={glbPreview}
                      scale={glbScale}
                      texture={glbTexture}
                    />
                  </div>
                )}

                {(!glb || !preview3d) && customText && (
                  <>
                    <FluidProductImage
                      className="productConfiguration"
                      imageUrl={imageUrls[0]}
                      productData={productData}
                    />
                    <div className="d-flex justify-content-center">
                      <span>
                        {t('Wunschtext')}: "{customText}"
                      </span>
                    </div>
                  </>
                )}

                {(!glb || !preview3d) &&
                  !customText &&
                  imageUrls.length == 1 && (
                    <FluidProductImage
                      className="productConfiguration"
                      imageUrl={imageUrls[0]}
                      productData={productData}
                    />
                  )}

                {(!glb || !preview3d) &&
                  !customText &&
                  imageUrls.length > 1 && (
                    <>
                      <ArtboxoneCarousel
                        key={'pes_carousel_' + (fullscreen ? 'f' : 'n')}
                        plugins={['arrows']}
                        showThumbs={true}
                      >
                        {imageUrls.map((imageUrl: string, i: number) => (
                          <img
                            key={
                              'preview_carousel_' +
                              i +
                              '_' +
                              (fullscreen ? 'f' : 'n')
                            }
                            src={imageUrl}
                          />
                        ))}
                      </ArtboxoneCarousel>
                    </>
                  )}

                <ArtistLinks
                  artists={artistsShow}
                  materialName={materialName}
                />
                <div className="d-flex justify-content-center">
                  <Favorite
                    material={materialName}
                    motiveId={productInformation.motiveId}
                    ratio={productData.ratio}
                    show={true}
                  />

                  {glb && (
                    <Preview3dButton
                      preview3d={preview3d}
                      setPreview3d={setPreview3d}
                    />
                  )}
                  {imageUrls.length >= 1 && (
                    <Fullscreen
                      fullscreen={fullscreen}
                      setFullscreen={setFullscreen}
                    />
                  )}
                </div>
              </div>
              <div
                className={fullscreen === true ? 'col-md-12' : 'col-md-6'}
                style={{ marginTop: `24px` }}
              >
                <ProductConfigurator
                  crosslinkButton={crosslinkButton}
                  customText={customText}
                  material={props.material}
                  materials={filteredMaterialsData}
                  openConfiguratorDialogButton={
                    hasItems && (
                      <Button
                        className="cta_pes_button"
                        color="primary"
                        data-test="cta_pes_add2cart_button_upselling"
                        onClick={() => {
                          setOpenUpSellingDialog(true)
                        }}
                        variant="contained"
                      >
                        <AddShoppingCartIcon />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t(
                              chosenMaterial.out_of_stock
                                ? 'Zur Zeit leider ausverkauft'
                                : buttonText,
                            ),
                          }}
                        />
                      </Button>
                    )
                  }
                  productData={productData}
                  productInformation={productInformation}
                />
              </div>
            </div>
            <div ref={crossLinkRef} />
            {crosslinksSameMotiveVisible === true &&
              crosstype !== 'calendar' && (
                <CrosslinksSameMotive
                  currentMaterial={props.material}
                  productData={productData}
                  setOnClick={setStateOff}
                />
              )}
            {crosslinksSameMotiveVisible === true &&
              crosstype === 'calendar' && (
                <ArtworksInsideCalendar
                  currentMaterial={props.material}
                  productData={productData}
                  setOnClick={setStateOff}
                />
              )}
            <MaterialText material={chosenMaterial} />
            <LastVisited url={props.url} />
            <CrosslinksSameMaterial
              currentMaterial={props.material}
              productData={productData}
              variant={materialVariant}
            />
            {hasItems && (
              <ConfigurationDialog
                chosenMaterial={chosenMaterial}
                imageUrl={imageUrls[0]}
                materials={materials}
                open={openUpSellingDialog}
                priceCountry={priceCountry}
                productData={productData}
                setOpen={setOpenUpSellingDialog}
              />
            )}
          </div>
          <JSONLD
            content={generateJsonLdForProduct(
              productData,
              sku,
              chosenMaterial,
              priceCountry,
              imageUrls,
              props.url,
            )}
          />
        </Layout>
      </>
    )
  }
}

export default withLocation(ProductConfiguration)
